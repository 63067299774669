<template>
  <NuxtLayout :name="layoutRef" class="px-safe">
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const layoutRef = ref('desktop')
const layout = () => {
  if (isMobile()) {
    return 'mobile'
  }
  return 'desktop'
}
layoutRef.value = layout()
</script>
